<template>
  <ModernModalLayout
    ref="modal"
    size="large"
    :title="message('title')"
    :watch-for="data"
  >
    <v-form :disabled="$wait('importing')" class="pb-3">
      <v-alert
        v-if="error"
        text
        border="left"
        type="error"
        transition="scale-transition"
      >
        {{ error }}
      </v-alert>
      <v-row>
        <slot name="prepend" v-bind="{ data, getValidationErrors }" />
        <v-col cols="6">
          <v-file-input
            v-model="data.file"
            required
            :clearable="false"
            hide-details="auto"
            solo
            flat
            dense
            :background-color="!$vuetify.theme.dark ? 'grey lighten-3' : ''"
            prepend-icon=""
            placeholder="Добавить csv файл"
            :error-messages="getValidationErrors('data.file')"
          />
        </v-col>
        <v-col v-if="linkToImportTemplate" cols="6">
          <v-btn
            class="caption"
            download="importTemplate.csv"
            color="primary"
            :href="linkToImportTemplate"
            large
            block
            text
          >
            <v-icon color="blue" :style="{ fontSize: '14px' }">
              fa-download
            </v-icon>
            <v-col class="d-flex flex-column">
              <span class="mb-1">Скачать шаблон</span>
              <span class="text--disabled">Импортируемого файла</span>
            </v-col>
          </v-btn>
        </v-col>
        <v-col v-if="chooseOnlyCreate" cols="6">
          <v-checkbox
            v-model="data.onlyCreate"
            class="mt-0"
            label="Только создание"
            hide-details
            :error-messages="getValidationErrors('data.onlyCreate')"
          />
        </v-col>
        <slot name="append" />
      </v-row>
    </v-form>
    <v-alert
      v-if="result"
      text
      border="left"
      :type="resultStatus"
      transition="scale-transition"
    >
      <div
        v-if="!createdRows.length && !updatedRows.length && !failedRows.length"
      >
        Изменения не проведены. Проверьте правильность заполнения приложенного
        файла и повторите снова
      </div>
      <div v-if="createdRows.length">
        Добавлено записей: {{ createdRows.length }}.
      </div>
      <div v-if="updatedRows.length">
        Обновлено записей: {{ updatedRows.length }}.
      </div>
      <div v-if="failedRows.length">
        В {{ failedRows.length }} строчках обнаружены ошибки, их не получилось
        обработать.
      </div>
    </v-alert>

    <template v-if="failedRows.length">
      <v-layout align-baseline="align-baseline">
        <h3 class="mt-3 red--text">
          {{ message('failedRowsTitle') }}
        </h3>
        <v-spacer />
        <v-btn
          class="caption"
          text
          :href="result.url"
          download="download"
          color="warning"
        >
          <v-icon color="warning" :style="{ fontSize: '14px' }">
            fa-download
          </v-icon>
          <v-col class="d-flex flex-column">
            <span class="mb-1">Скачать файл со строчками</span>
            <span class="text--disabled">В которых произошли ошибки</span>
          </v-col>
        </v-btn>
      </v-layout>
      <ol>
        <li v-for="item in failedRows" :key="item.row">
          <strong>Строка {{ item.row }}:</strong>
          <div v-for="(text, index) in item.messages" :key="index">
            {{ text }}
          </div>
        </li>
      </ol>
    </template>

    <v-expansion-panels tile>
      <v-expansion-panel v-if="message('instruction').length > 0">
        <v-expansion-panel-header class="text-h6">
          {{ message('instructionTitle') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content class="text-body-2">
          <div
            v-for="(item, index) in message('instruction')"
            :key="index"
            class="pb-2 pre-wrap"
          >
            <template v-if="Array.isArray(item)">
              <!-- eslint-disable vue/no-v-html -->
              <div
                v-for="(innerItem, index2) in item"
                :key="index2"
                class="pb-2"
                v-html="innerItem"
              />
              <!-- eslint-enable vue/no-v-html -->
            </template>
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-else v-html="item" />
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <template #actions:append>
      <v-btn
        class="px-10"
        depressed
        :disabled="$wait('importing')"
        color="primary"
        @click="handleImport"
      >
        {{ message('actionButton') }}
      </v-btn>
    </template>
  </ModernModalLayout>
</template>

<script>
import required from 'vuelidate/lib/validators/required';
import messagable from '@/utils/mixins/messagable';
import waitable from '@/utils/mixins/waitable';
import validationMixin from '@/utils/validation';
import { isXhrError, xhrErrorMessage } from '@/utils/helpers';
import ModernModalLayout from '@/components/layouts/ModernModalLayout.vue';

const MESSAGES = {
  title: 'Импорт',
  actionButton: 'Импортировать',
  successfulAction: 'Ресурс успешно импортирован',
  errorAction: 'Произошла ошибка импортирования ресурса',
  failedRowsTitle: 'Ошибки в импортируемом файле:',
  instructionTitle: 'Инструкция',
  instruction: [],
};

export default {
  components: {
    ModernModalLayout,
  },

  mixins: [validationMixin, messagable(MESSAGES), waitable],

  props: {
    onSubmit: { type: Function, required: true },
    entity: { type: Array, required: true },
    linkToImportTemplate: { type: String, default: null },
    chooseOnlyCreate: Boolean,
  },

  validations() {
    const customValidations = Object.fromEntries(
      this.entity
        .filter(field => field.validation)
        .map(field => {
          return [field.key, field.validation];
        }),
    );

    return {
      data: {
        file: { required },
        onlyCreate: { required },
        ...customValidations,
      },
    };
  },

  data: () => ({
    data: {
      file: null,
      onlyCreate: false,
    },
    result: null,
    error: null,
  }),

  computed: {
    createdRows() {
      return this.result?.created || [];
    },
    updatedRows() {
      return this.result?.updated || [];
    },
    failedRows() {
      if (!this.result?.failed) return [];
      return this.result.failed.map(({ row, errors }) => {
        return {
          row,
          messages: errors.map(({ message }) => message),
        };
      });
    },
    resultStatus() {
      if (this.failedRows.length === 0) return 'success';
      else if (
        this.failedRows.length > 0 &&
        this.updatedRows.length === 0 &&
        this.createdRows.length === 0
      )
        return 'error';
      return 'warning';
    },
  },

  methods: {
    validate() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    async handleImport() {
      if (!this.validate()) return;

      this.error = null;
      this.result = null;

      try {
        this.result = await this.$loading(
          this.onSubmit(this.data),
          'importing',
        );

        if (this.result?.failed?.length) {
          this.$notify({
            group: 'note',
            type: 'error',
            title: 'При импорте данных обнаружены ошибки',
          });
        } else {
          this.$notify({
            group: 'note',
            type: 'info',
            title: 'Файл успешно импортирован',
          });
        }
      } catch (err) {
        this.error = isXhrError(err) ? xhrErrorMessage(err) : err.message;
      }

      // Очистить выбранный файл и снять валидацию на закрытие
      // измененной модалки
      this.data.file = null;
      this.$refs['modal'].undirty();
      this.$v.$reset();
    },
  },
};
</script>

<style scoped>
.pre-wrap {
  white-space: pre-wrap;
}
</style>
